import { cloneDeep } from "lodash";
// import { useRoundsContext } from "../../../components/contexts/RoundsContext";
import { useUserContext } from "../../../components/contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { useSelector } from "react-redux";

const TableRow = ({ index, date, course, SGS, strokes, minutes, seconds, openDeleteDialog }) => {
  // let { rounds, setRounds } = useRoundsContext();
  // const { user } = useUserContext();
  const user = useSelector(state => state.user.user);
  const navigate = useNavigate();

  const editRoundHandler = index => {
    navigate(`/rounds/editRound/${index}`);
  };

  return (
    <tr id={`r-${index + 1}`} className='row-item' key={index}>
      <td>{date}</td>
      <td>{course}</td>
      <td>
        {SGS} ({strokes} in {minutes}:{seconds})
      </td>
      <td>
        <button
          aria-label='View and Edit Round'
          onClick={() => {
            editRoundHandler(index);
          }}
        >
          <span className='fas fa-eye' aria-hidden='true'></span>
          &nbsp;
          <span className='fas fa-edit' aria-hidden='true'></span>
        </button>
      </td>
      <td>
        <button
          aria-label='Delete Round'
          onClick={openDeleteDialog}
        >
          <span className='fas fa-trash' aria-hidden='true'></span>
        </button>
      </td>
    </tr>
  );
};

export default TableRow;
