import PageUnderConstruction from "../../shared/PageUnderConstruction/PageUnderConstruction";

const Competitions = () => {
  return (
    <>
      <div id='tournamentsModeTab' className='mode-page' role='tabpanel' aria-label='tournaments Tab' tabIndex='0'>
        <PageUnderConstruction heading={"Competitions"} />
        {/* <button id="buddiesModeActionBtn" type="button" className="float-btn">
          <span className="fas fa-search fa-fw" aria-hidden="true"></span>
          Tournaments
        </button> */}
      </div>
    </>
  );
};

export default Competitions;
