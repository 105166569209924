 /*************************************************************************
 * File: coursesModeDetailsMapHoleProfile.js
 * This file defines the CoursesModeDetailsHolePofile React component, 
 * which displays the elevation profile of the selected hole in the 
 * "Map tab. It uses the react-chartjs-2 library to display the 
 * hole profile as a line chart. 
 * ************************************************************************/
import {useRef, useEffect, useState} from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import * as SGCalcs from '../../../speedgolfCalculations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCourse } from '../../../components/contexts/CourseContext';
import { useTeeUnits } from '../../../components/contexts/TeeUnitsContext';

export default function CoursesModeDetailsMapHoleProfile({profileHole, setProfileHole}) {
    const course = useCourse();
    const teeUnits = useTeeUnits();
    let conversionFactor = useRef(1);

    const [holeData, setHoleData] = useState({
        labels:[],
        datasets:[]
      });
      
      const options ={
        scales: {
            x: {
              type: 'linear',
              ticks: {
                display: true,
                stepSize: 50,
              },
              grid: {
                drawBorder: true,
                display: true,
              },
            },
            y: {
              ticks: {
                display: true,
                beginAtZero: true,
              },
              grid: {
                drawBorder: true,
                display: true,
              },
            }
          }
      }
    
      const holeProfileBackground = {
        id: "holeProfileBackground",
        beforeDatasetsDraw(chart, args, pluginOptions){
          const {ctx, chartArea: {top, bottom, left, right, width, height}} = chart;
          ctx.save()
          const grd = ctx.createLinearGradient(left, top, width, height);
          grd.addColorStop(0, "gray");
          grd.addColorStop(1, "white");
          ctx.fillStyle = grd
          ctx.fillRect(left, top, width, height)
        }
      }

   useEffect(()=>{
        /*************************************************************************
         * @function showHoleProfile
         * @Desc
         * This function is called by the useEffect hook to update the holeData
         * state variable, which is used by the Line component to display the   
         * elevation profile of the selected hole.
         * *************************************************************************/
        function showHoleProfile() {
            const currentHoleObj = course.tees[teeUnits.selectedTee].holes[profileHole-1];
            const tempLabels = [];
            let transitionPathLength;
            let transitionPathSampled; 
            let golfPathLength;
            let golfPathSampled;
            if (profileHole==1 && course.tees[teeUnits.selectedTee].hasStartLine) {
                if (currentHoleObj.startPath !== "") {
                  transitionPathLength = currentHoleObj.startPathSampled.length;// / 3;
                  transitionPathSampled = currentHoleObj.startPathSampled;
                } else {
                  transitionPathLength = 0;
                  transitionPathSampled = [];
                }
            } else {
              transitionPathLength = currentHoleObj.transitionPathSampled.length;// / 3;
              transitionPathSampled = currentHoleObj.transitionPathSampled;
            }
            golfPathLength = currentHoleObj.golfPathSampled.length;// / 3;
            golfPathSampled = currentHoleObj.golfPathSampled;
            let currentDistance = 0;
            let transitionPathData = [];
            for (let i = 0; i< transitionPathLength; i++){
            if (i === 0){
                tempLabels.push(0);
            }
            else{
                let tempDist = SGCalcs.getDistance([transitionPathSampled[i-1], transitionPathSampled[i]])
                currentDistance += parseInt(tempDist/conversionFactor.current)
                tempLabels.push(currentDistance);
            
            }
            transitionPathData.push({x: tempLabels[i], y: transitionPathSampled[i].elv})
            }
        
            let golfPathData = [];
            for (let i = 0; i< golfPathLength; i++){
            if (i !== 0){
                let tempDist = SGCalcs.getDistance([golfPathSampled[i-1], golfPathSampled[i]])
                currentDistance += parseInt(tempDist/conversionFactor.current)
                tempLabels.push(currentDistance)
            }
            golfPathData.push({x: tempLabels[transitionPathLength + i - 1], y:golfPathSampled[i].elv})
            }
            
            setHoleData({
                labels: tempLabels.slice(0,tempLabels.length),
                datasets:[
                    {
                        label: "Transition Path",
                        // fill: true,
                        backgroundColor: ["yellow"],
                        borderColor: ["yellow"],
                        data: transitionPathData,
                    },
                    {
                        label: "Golf Path",
                        // fill: "start",
                        backgroundColor: ["red"],
                        borderColor: ["red"],
                        data: golfPathData,
                    }, 
                ]
            })
        }
        if (teeUnits.selectedUnits === "Imperial")
            conversionFactor.current = 3;
        else
            conversionFactor.current = 3.281; //metric

        showHoleProfile();
   },[teeUnits.selectedUnit, profileHole]);  

   //JSX render code
    return (
        <div className="hole-profile">
             <div className="flex-container">
               <div><h5>{"Hole #" + profileHole + " Elevation Profile"}</h5></div>
               <div><button onClick={()=>setProfileHole(0)}><FontAwesomeIcon icon="xmark"/></button></div>
             </div>
             <div className="flex-container">
               <Line id="holeProfile" data={holeData} options= {options} plugins={[holeProfileBackground]} height={"9%"} width={"100%"}/>
             </div>
         </div>
    );
}
