import { useNavigate, useParams } from "react-router-dom";
import { useUserContext } from "../../../components/contexts/UserContext";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { convertFeetToMiles, convertKmToMiles, convertMilesToFeet, convertMilesToKm } from "../utils/utils";
import { format } from "date-fns/format";
import { notifyMessage } from "../../../services/toasterServices";
// import { useRoundsContext } from "../../../components/contexts/RoundsContext";
import { useSelector, useDispatch } from "react-redux";
import { setRounds } from "../roundSlice";

const useLogRound = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const { user } = useUserContext();
    const user = useSelector(state => state.user.user);
    // const { setRounds } = useRoundsContext();
    const { authenticated, email } = user.accountInfo;
    const { rounds, roundCount } = JSON.parse(localStorage.getItem(email));

    useEffect(() => {
        if (!authenticated) navigate("/login");
    }, [authenticated]);

    const { index } = useParams();
    const isNew = index == null;
    const round = rounds.find(({ roundNum }) => roundNum == index );

    const [distanceUnit, setDistanceUnit] = useState("miles");

    const { setValue, getValues, watch, ...methods } = useForm({
        defaultValues: round ? {
            ...round,
            distance: convertFeetToMiles(round.distance)?.toFixed(2),
        } : {
            course: "",
            date: format(new Date(), "yyyy-MM-dd"),
            type: "practice",
            holes: 18,
            strokes: 80,
            minutes: 60,
            seconds: "00",
            SGS: "140:00",
        },
        reValidateMode: "onSubmit",
    });

    const sgsHandler = () => {
        const [ strokes, minutes, seconds ] = getValues(["strokes", "minutes", "seconds"]);
        const formattedSeconds = seconds.padStart(2, "0")
        setValue("SGS", (strokes + minutes) + ":" + formattedSeconds);
        setValue("seconds", formattedSeconds);
    }

    const distanceUnitKeyHandler = (e) => {
        if (e.key === "ArrowRight" && distanceUnit === "miles") {
            distanceUnitHandler();
        }

        if (e.key === "ArrowLeft" && distanceUnit === "km") {
            distanceUnitHandler();
        }
    }

    const distanceUnitHandler = () => {
        setDistanceUnit(prev => prev === "miles" ? "km" : "miles");
        const [currentDistance] = getValues(["distance"]);
        const convertFunction = distanceUnit === "miles" ? convertMilesToKm : convertKmToMiles;

        setValue("distance", convertFunction(currentDistance).toFixed(2));
    };

    const formatDistance = () => {
        const currentDistance = watch('distance');

        if (currentDistance) {
            setValue("distance", Number(currentDistance).toFixed(2));
        }
    };

    const closeLogPage = () => navigate("/rounds");

    const onSubmit = (data) => {
        const distance = convertMilesToFeet(
            distanceUnit === 'miles' ? +data.distance : convertKmToMiles(+data.distance)
        );

        const updatedRounds = isNew ?
            [...rounds, { ...data, roundNum: roundCount + 1, distance }] :
            rounds.map((round) => {
                if (round.roundNum == index) {
                    return {
                        ...round,
                        ...data,
                        distance
                    }
                }
                return round;
            })

        localStorage.setItem(user.accountInfo.email, JSON.stringify({ ...user, rounds: updatedRounds, roundCount: roundCount + 1 }));
        // setRounds(updatedRounds);
        dispatch(setRounds(updatedRounds));

        const notifyMessageTitle = isNew ? "New round created!" : "Round Updated!";
        notifyMessage("success", notifyMessageTitle, 3000, "colored", "top-center");

        closeLogPage();
    };

    return {
        isNew,
        onSubmit,
        sgsHandler,
        distanceUnit,
        formatDistance,
        distanceUnitHandler,
        distanceUnitKeyHandler,
        closeLogPage,
        methods
    }
}

export default useLogRound;