import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../components/contexts/UserContext";
import NoRoundsMessage from "../components/NoRoundsMessage";
import TableRow from "../components/TableRow";
// import { useRoundsContext } from "../../../components/contexts/RoundsContext";
import { sortRounds } from "../../../services/roundsServices";

import { searchRounds } from "../../../services/roundsServices";
import { intersectionBy } from "lodash";
import ModalDialog from "../../shared/ModalDialog";
import { Bounce, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { setRounds } from "../roundSlice";

const Rounds = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { user } = useUserContext();
  const user = useSelector(state => state.user.user);
  // const { rounds, setRounds } = useRoundsContext();
  const rounds = useSelector(state => state.rounds);
  // State to keep track of sorting configuration
  const [sortConfig, setSortConfig] = useState({
    columnName: null,
    direction: "none"
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRounds, setFilteredRounds] = useState(rounds);
  const [deleteDialogId, setDeleteDialogId] = useState(null);

  useEffect(() => {
    setFilteredRounds(prev => intersectionBy(prev, rounds, 'roundNum'));
  }, [rounds]);

  // let userAuthenticated = user.accountInfo.authenticated;
  const userAuthenticated = useSelector(state => state.user.authenticated);

  //TODO Added disabled button for now, not called 
  const newRoundClickHandler = () => {
    navigate("/rounds/newRound");
  };
  
  
  let storedUser = null;
  if (userAuthenticated) {
    storedUser = JSON.parse(localStorage.getItem(user.accountInfo.email));
  }

  const handleSort = columnName => {
    let direction = "ascending";
    if (sortConfig.columnName === columnName && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ columnName, direction });

    const sortedData = sortRounds(rounds, columnName, direction);
    // setRounds(sortedData);
    dispatch(setRounds(sortedData));
  };

  const handleSearchChange = event => {
    const value = event.target.value;
    setSearchTerm(event.target.value);
    if (value) {
      const filteredRounds = searchRounds(rounds, event.target.value);
      setFilteredRounds(filteredRounds);
    } else {
      setFilteredRounds(rounds);
    }
  };

  const getSortIconClass = columnName => {
    let defaultIconClass = "fas fa-sort sort-icon";
    if (sortConfig.columnName === columnName) {
      switch (sortConfig.direction) {
        case "descending":
          return "fas fa-sort-amount-down sort-icon";
        case "ascending":
          return "fas fa-sort-amount-up sort-icon";
        default:
          break;
      }
    }
    return defaultIconClass;
  };

  const deleteRoundHandler = index => {
    let storedUser = JSON.parse(localStorage.getItem(user.accountInfo.email));
    storedUser.rounds = storedUser.rounds.filter(({ roundNum }) => roundNum !== index);
    localStorage.setItem(user.accountInfo.email, JSON.stringify(storedUser));
    // setRounds(storedUser.rounds);
    dispatch(setRounds(storedUser.rounds));
    toast.error("Deleted round successfully", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce
    });
  };

  return (
    <>
      {!userAuthenticated && <h1>Loading</h1>}
      {userAuthenticated && (
        <div
          id='roundsModeTab'
          // className="mode-page hidden"
          className='mode-page'
          role='tabpanel'
          aria-label='Rounds Tab'
          tabIndex='0'
        >
          <h1 className='mode-page-header'>Rounds </h1>
          <div className='d-flex justify-content-center align-items-center'>
            <div className='me-2'>Search/Filter:</div>
            <div className='w-25'>
              <input
                type='text'
                className='form-control'
                placeholder=''
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div
            id='roundUpdated'
            className='toast-container hidden'
            role='alert'
            aria-atomic='true'
            aria-live='assertive'
          >
            <div id='roundUpdatedMsg' className='toast-text'>
              New round was logged
            </div>
            <button id='roundUpdatedClose' type='button' className='btn-close toast-close' aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <table id='roundsTable' className='table table-hover'>
            <caption id='roundsTableCaption' aria-live='polite'>
              Displaying {filteredRounds.length} of {rounds.length} speedgolf rounds
            </caption>
            <thead className='table-light'>
              <tr>
                <th scope='col' role='columnheader' className='sortable-header cell-align-middle' aria-sort='none'>
                  <button
                    className='btn bg-transparent table-sort-btn'
                    aria-label='Sort ascending by date'
                    onClick={() => handleSort("date")}
                  >
                    <span className={getSortIconClass("date")}></span>
                  </button>
                  Date
                </th>
                <th scope='col' role='columnheader' className='sortable-header cell-align-middle' aria-sort='none'>
                  <button
                    className='btn bg-transparent table-sort-btn'
                    aria-label='Sort ascending by course'
                    onClick={() => handleSort("course")}
                  >
                    <span className={getSortIconClass("course")}></span>
                  </button>
                  Course
                </th>
                <th scope='col' role='columnheader' className='sortable-header cell-align-middle' aria-sort='none'>
                  <button
                    className='btn bg-transparent table-sort-btn'
                    aria-label='Sort ascending by score'
                    onClick={() => handleSort("SGS")}
                  >
                    <span className={getSortIconClass("SGS")}></span>
                  </button>
                  Score
                </th>
                <th scope='col' className='cell-align-middle'>
                  View/Edit...
                </th>
                <th scope='col' className='cell-align-middle'>
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {searchTerm ? (
                filteredRounds.length === 0 ? (
                  <NoRoundsMessage />
                ) : (
                  filteredRounds.map((element, index) => (
                    <TableRow
                      index={element.roundNum}
                      date={element.date}
                      course={element.course}
                      minutes={element.minutes}
                      seconds={element.seconds}
                      SGS={element.SGS}
                      strokes={element.strokes}
                      key={element.course + index}
                      openDeleteDialog={() => setDeleteDialogId(element.roundNum)}
                    />
                  ))
                )
              ) : (
                rounds.map((element, index) => (
                  <TableRow
                    index={element.roundNum}
                    date={element.date}
                    course={element.course}
                    minutes={element.minutes}
                    seconds={element.seconds}
                    SGS={element.SGS}
                    strokes={element.strokes}
                    key={element.course + index}
                    openDeleteDialog={() => setDeleteDialogId(element.roundNum)}
                  />
                ))
              )}
            </tbody>
          </table>
          <ModalDialog
            isOpen={deleteDialogId}
            title="Delete Round?"
            body="Do you really want to delete that round?"
            actionBtnText="Yes, Delete Round"
            cancelBtnText="No, Cancel"
            close={() => setDeleteDialogId(null)}
            onSubmit={() => deleteRoundHandler(deleteDialogId)}
          />
          <button id='roundsModeActionBtn' type='button' className='float-btn'// onClick={newRoundClickHandler} disbled for now 
          >
            <span className='fas fa-calendar-plus fa-fw' aria-hidden='true'></span>
            New Round
          </button>
        </div>
      )}
    </>
  );
};

export default Rounds;
