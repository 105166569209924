import { createSlice } from "@reduxjs/toolkit";
import { initial, reduce } from "lodash";


const roundsSlice = createSlice({
  name: 'rounds',
  initialState: [],
  reducers: {
    setRounds(state, action) {
      console.log('Setting rounds in state', action.payload);
      state = action.payload;
      return state;
    },
    addRound(state, action){
      state.push(action.payload);
    },
    removeRound(state, action){
      return state.filter(round => round.id !== action.payload.id);
    },
    updateRound(state, action){
      const {id, ...newRound} = action.payload;
      const roundIndex = state.findIndex(round => round.id === id);
      state[roundIndex] = newRound;
    }
  }
})

export const { setRounds, addRound, removeRound, updateRound } = roundsSlice.actions;
export default roundsSlice.reducer;