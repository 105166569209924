import { Controller } from "react-hook-form";

import isEmpty from "lodash/isEmpty";
import capitalize from "lodash/capitalize";

import Navbar from "../../shared/Navbar/Navbar";

import { validationErrorMap } from "../utils/constants";
import useLogRound from "../hooks/functions";
import ErrorMessage from "../../shared/BaseFormComponents/ErrorMessage";
import AddCourseAutocomplete from "../../../components/AddCourseAutocomplete";

const LogRound = () => {
  const { isNew, methods, onSubmit, sgsHandler, distanceUnit, formatDistance, distanceUnitHandler, distanceUnitKeyHandler, closeLogPage } = useLogRound();
  const { control, register, handleSubmit, formState: { errors } } = methods;

  return (
    <>
      <Navbar />
      <div
        id='roundsModeDialog'
        className='mode-page action-dialog'
        role='dialog'
        aria-modal='true'
        aria-labelledby='roundFormHeader'
        tabIndex='0'
      >
        <h1 id='roundFormHeader' className='mode-page-header'>
          {isNew ? "Add" : "Edit"} Round
        </h1>
        {!isEmpty(errors) && (
          <p id="roundErrorBox" className="alert alert-danger centered">
            {Object.keys(errors).map((field) => {
              const id = "round" + capitalize(field);
              return <ErrorMessage key={id} id={id}>{validationErrorMap[id]}</ErrorMessage>
            })}
          </p>
        )}
        <form id='logRoundForm' className='centered' noValidate onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-3'>
            <label htmlFor='roundDate' className='form-label'>
              Date:
              <input
                id='roundDate'
                className='form-control centered'
                type='date'
                aria-describedby='roundDateDescr'
                {...register("date", {
                  required: true
                })}
              />
            </label>
            <div id='roundDateDescr' className='form-text'>
              Enter a valid date
            </div>
          </div>
          <div className='mb-3'>
            <label htmlFor='roundCourse' className='form-label'>
              Course:
              <Controller
                  name="course"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...rest } }) => <AddCourseAutocomplete {...rest} ref={ref} />}
              />
              <div id='roundCourseDescr' className='form-text'>
                Choose a course from the dropdown list
              </div>
            </label>
          </div>
          <div className='mb-3'>
            <label htmlFor='roundType'>
              Type:
              <select
                  name='roundType'
                  id='roundType'
                  className='form-control centered'
                  {...register("type")}
              >
                <option value='practice'>Practice</option>
                <option value='tournament'>Tournament</option>
              </select>
            </label>
          </div>
          <div className="mb-3">
            <label htmlFor="roundHoles">
              Holes:
              <select
                name="roundHoles"
                id="roundHoles"
                className="form-control centered"
                {...register("holes")}
              >
                <option value="9">9</option>
                <option value="18">18</option>
              </select>
            </label>
          </div>
          <div className='mb-3'>
            <label htmlFor='roundStrokes'>
              Strokes:
              <input
                id='roundStrokes'
                className='form-control centered'
                type='number'
                size='3'
                min='9'
                max='200'
                aria-describedby='roundStrokesDescr'
                {...register("strokes", {
                  onBlur: sgsHandler,
                  valueAsNumber: true,
                  min: 9,
                  max: 200,
                  required: true
                })}
              />
            </label>
            <div id='roundStrokesDescr' className='form-text'>
              Enter a strokes value between 9 and 200
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="roundTime">
              Time:
              <br/>
              <input
                id="roundMinutes"
                type="number"
                size="3"
                aria-describedby="roundTimeDescr"
                min="10"
                max="400"
                style={{textAlign: "right"}}
                {...register("minutes", {
                  onBlur: sgsHandler,
                  valueAsNumber: true,
                  min: 10,
                  max: 400,
                  required: true
                })}
              />
              :
              <input
                id="roundSeconds"
                type="number"
                size="2"
                min="0"
                max="60"
                aria-describedby="roundTimeDescr"
                {...register("seconds", {
                  onBlur: sgsHandler,
                  min: 0,
                  max: 60,
                  required: true,
                })}
              />
            </label>
            <div id="roundTimeDescr" className="form-text">
              Enter a minutes value between 10 and 400, and a
              seconds value between 0 and 59
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="roundSGS">
              Speedgolf Score:
              <br/>
              <input
                  id="roundSGS"
                  className="form-control centered"
                  type="text"
                  size="6"
                  disabled
                  {...register("SGS")}
              />
            </label>
          </div>
          <div className="mb-3">
            <label htmlFor="roundDistance" className="form-label">
              Distance:
              <input
                id="roundDistance"
                className="form-control centered"
                type="number"
                aria-describedby="roundDistanceDescr"
                step={0.01}
                {...register("distance", {
                  min: distanceUnit === "miles" ? 0.01 : 0.10,
                  max: distanceUnit === "miles" ? 62 : 100,
                  onBlur: formatDistance,
                })}
              />
            </label>
          </div>
          <div className="d-flex justify-content-center mb-2">
            Miles
            <div className="form-switch">
              <input
                className="form-check-input centered"
                type="checkbox"
                role="switch"
                checked={distanceUnit !== "miles"}
                onChange={distanceUnitHandler}
                onKeyDown={distanceUnitKeyHandler}
              />
            </div>
            Kilometers
          </div>
          <div className="mb-3">
            <div id="roundDistanceDescr" className="form-text">
              Enter a distance value (in miles or km) between 0.01 and 62 miles (100 km)
            </div>
          </div>
          <div className='mb-3'>
            <label htmlFor='roundNotes'>
              Notes:
              <br/>
              <textarea
                id='roundNotes'
                className='form-control'
                aria-describedby='roundNotesDescr'
                rows='6'
                cols='75'
                {...register("notes", {maxLength: 500})}
              />
            </label>
            <div id='roundNotesDescr' className='form-text'>
              Enter optional round notes of up to 500 characters
            </div>
          </div>
          <div className='mode-page-btn-container'>
            <button id='roundFormSubmitBtn' className='mode-page-btn action-dialog action-button' type='submit'>
              <span id='roundFormSubmitBtnIcon' className={`fa ${isNew ? "fa-save" : "fa-edit"}`}></span>
              <span id='roundFormSubmitBtnLabel'>
                &nbsp;
                {isNew ? "Add" : "Update"} Round
              </span>
            </button>
            <button
              id='roundsModeLogCancelBtn'
              className='mode-page-btn-cancel action-dialog cancel-button'
              type='button'
              onClick={closeLogPage}
            >
              <span className='fa fa-window-close'></span>
              &nbsp;Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default LogRound;
